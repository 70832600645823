import { createSlice } from '@reduxjs/toolkit';
import api from 'src/api/index';
import { showErrorToast } from './toast';

const initialState = {
	list: [],
	total: 0,
	loading: false,
};

const consultationsSlice = createSlice({
	name: 'consultations',
	initialState,
	reducers: {
		setConsultationsList(state, action) {
			state.list = action.payload.list;
			state.total = action.payload.count;
		},
		setConsultationsLoading(state, action) {
			state.loading = action.payload;
		},
		addConsultationItem(state, action) {
			state.list.push(action.payload);
		},
		updateConsultationItem(state, action) {
			const index = state.list.findIndex((item) => Number(item.id) === Number(action.payload.id));
			state.list.splice(index, 1, action.payload);
		},
		removeConultationItem(state, action) {
			state.list = state.list.filter((item) => item.id !== action.payload);
		},
		
	},
});

export const {
	setConsultationsList,
	setConsultationsLoading,
	addConsultationItem,
	updateConsultationItem,
	removeConultationItem,
} = consultationsSlice.actions;

export const reloadConsultation = (id) => async (dispatch) => {
	dispatch(setConsultationsLoading(true));
	try {

		const response = await api.consultationById(id);
		dispatch(updateConsultationItem(response));

	} catch (error) {

		console.error(error);
		dispatch(
			showErrorToast({
				title: 'Ошибка',
				description: 'Не удалось обновить консультацию',
			})
		);

	} finally {

		dispatch(setConsultationsLoading(false));

	}
};

export const getConsultationById = (id) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {

		const state = getState();
		const list = state.consultations.list;
		let item = list.find((item) => Number(item.id) === Number(id));

		if (item) {
			resolve(item);
		} else {

			api.consultationById(id)
				.then((res) => {
					resolve(res);
					dispatch(addConsultationItem(res));
				})
				.catch(() => {
					dispatch(
						showErrorToast({
							title: 'Ошибка',
							description: `Консультация ${id} не найдена`,
						})
					);
					reject();
				});
				
		}
	});
};

export const updateConsultation = ( { consultationId, payload } ) => async (dispatch) => {
	dispatch(setConsultationsLoading(true));
	try {
		
		const res = await api.editConsultation({ consultationId, payload });
		if (res) {
			dispatch(reloadConsultation(consultationId));
		}

	} catch (error) {
		console.error(error);
		dispatch(showErrorToast({
			title: 'Ошибка',
			description: 'Ошибка при получении списка публикаций'
		}));
	} finally {
		dispatch(setConsultationsLoading(false));
	}

};

export const fetchConsultationList = (payload = {}) => async (dispatch) => {
	dispatch(setConsultationsLoading(true));
	try {

		const res = await api.consultationsList(payload);
		dispatch(setConsultationsList(res));

	} catch (error) {

		console.error(error);
		dispatch(showErrorToast({
			title: 'Ошибка',
			description: 'Ошибка при получении списка публикаций'
		}));
	} finally {

		dispatch(setConsultationsLoading(false));

	}
};

export default consultationsSlice.reducer;
