import $axios from './api';

export const list = async (payload = {}) => {
	const { data } = await $axios.post('/v1/author/consultation/list', payload);
	return data;
};

export const getById = async (consultationId) => {
	const { data } = await $axios.get(`/v1/author/consultation/${ consultationId }`);
	return data;
};

export const create = async ( consultation ) => {
	const { data } = await $axios.post('/v1/author/consultation/create', consultation);
	return data;
};

export const edit = async ( { consultationId, payload } ) => {
	const { data } = await $axios.post(`/v1/author/consultation/${consultationId}`, payload);
	return data;
};

export const consumerList = async (payload = {}) => {
	const { data } = await $axios.get('/v1/author/consultation/consumer/list', payload);
	return data;
};

export const consumerStatusUpdate = async ({ consultationId, consumerId, status }) => {
	const { data } = await $axios.post(`/v1/author/consultation/${consultationId}/consumer/${consumerId}/status`, { status });
	return data;
};